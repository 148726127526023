/**
 * 重构 http 
 * vue3+vite+pinia
 *  VITE_API_BASE_URL; show msg 放到 base里处理; token 用 useAuthStore
 * zhaixiang 20240525,0915
 */
import axios from 'axios'
import { useAuthStore } from '@/stores/auth.js'

const baseURL = import.meta.env.VITE_API_BASE_URL || ''
let handle = [], loading = false

export const rp = axios.create({
  baseURL,
  timeout: 30 * 1000, // 默认值是 `0` (永不超时)
})

rp.interceptors.request.use(conf => {
  if (conf.method === 'get' && !conf.params && conf.data) conf.params = conf.data

  const { token } = useAuthStore()
  !token || conf.headers.authorization || conf.headers.Authorization || (conf.headers.authorization = token)

  return conf
})

rp.interceptors.response.use(
  res => {
    // console.log('http res:', res)
    // const { data, status, headers, config } = res
    return res.data
  },

  err => {
    // console.log('axios err:', err)
    const { response, config, code, message } = err
    let isTimeout = err.message.includes("timeout")
    if (isTimeout || !response) {
      console.error(isTimeout ? "请求超时！请您稍后重试" : `${code} ${message}`)
      return Promise.reject(err)
    }

    if (response.status === 401) {
      const { status, data, headers } = response
      const { code } = data
      // const refreshToken = getRefreshToken()
      const { refreshToken, getTokenByRefreshToken } = useAuthStore()

      if (refreshToken && (code === 10041 || code === 10051 || code === 10011 || code === 10010)) {
        const { promise, resolve, reject } = new Deferred()
        handle.push({ resolve, reject, config })
        if (!loading) {
          loading = true
          getTokenByRefreshToken(refreshToken).then(data => {
            while (handle.length > 0) {
              const rs = handle.shift()
              rs.config.headers.authorization = data.accessToken
              rp(rs.config).then(res => {
                return rs.resolve(res)
              }).catch(e => {
                // if (e?.response?.statusCode === 401) router.push({ name: 'login', replace: true })
                // else 
                rs.reject(e)
              })
            }
            loading = false
          }).catch(e => {
            // router.push({ name: 'login', replace: true })
            handle = []
            loading = false
            return Promise.reject(e)
          })
        }

        return promise
      }
    }

    console.error('axios err:', message, err)
    response.status === 500 && response.data && (response.data.statusCode = 500)

    return Promise.reject(response.data || err)
  }
)

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
}