/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-06-19 08:39:40
 * @FilePath: \js-yigrow-system\src\configs\menus\water-ferti-manage.js
 * @Description: 水肥管理菜单列表
 */
export default [
    {
        icon: "mdi-air-filter",
        text: "水肥中心",
        link: "/water-ferti/water-ferti-center",
        name:"water-ferti",
        menuKey: ["fertilizer"]
    },
    // {
    //     icon: "mdi-gradient-vertical",
    //     text: "喷雾系统",
    //     name:"fog-system",
    //     link: "/water-ferti/fog-system",
    //     menuKey: ["atomizing"]
    // },
    {
        icon: "mdi-shower-head", //mdi-shaker-outline//mdi-shower-head/mdi-sprinkler-fire
        text: "menuName.drugSystem",//打药系统
        name: "dosing-system",
        link: "/water-ferti/dosing-system",
        menuKey: ["drug"]
    },
    {
        icon: " mdi-awning-outline",
        text: "menuName.balconyGarden",//阳台种菜机
        name: "vegetable-plant",
        link: "/water-ferti/vegetable-plant",
        menuKey: ["planting"]
    },
    // {
    //     icon: "mdi-waterfall",
    //     text: "menuName.inOutWaterFertilizer",//进取型水肥控制系统
    //     name: "inOut-waterFerti",
    //     link: "/water-ferti/inOut-waterFerti",
    //     // menuKey: ["inoutfertilizer"]
    // },
    // {
    //     icon: "mdi-air-humidifier",
    //     text: "预调酸",
    //     link: "/water-ferti/acidAdjuste",
    //     name:"",
    //     menuKey: ["drug"]
    // },
    // {
    //     icon: "mdi-octagram-outline",
    //     text: "紫外消毒",//mdi-sun-wireless-outline//mdi-track-light
    //     link: "/water-ferti/uvDisinfection",
    //     name:"",
    //     menuKey: ["drug"]
    // },
    // {
    //     icon: "mdi-tune",
    //     text: "防洪排涝",
    //     link: "/water-ferti/floodControl",
    //     name:"",
    //     menuKey: ["drug"]
    // },

]