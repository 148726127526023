/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-06-20 10:16:47
 * @FilePath: \js-yigrow-system\src\router\index.js
 * @Description: 若热更新失效，请检查组件名称是否有误，大写对大写，小写对小写
 */

import { createRouter, createWebHistory, createWebHashHistory } from "vue-router"
import { notLoginRoute } from '@/configs/index.js'
import { useAuthStore } from "@/stores/auth"
// import { getToken } from "@/utils/token"
import { routes } from './routes.js'

export const dynamicRoutes = []

const router = createRouter({
  // history: import.meta.env.DEV ? createWebHistory() : createWebHashHistory(),
  history: createWebHashHistory(),

  // hash模式：createWebHashHistory，history模式：createWebHistory
  // process.env.NODE_ENV === "production"

  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

function isLoginRequired(name) {
  return !notLoginRoute.includes(name)
}

router.beforeEach((to, from) => {
  const { isLogin } = useAuthStore()
  // console.log('Navigating to:', to.path)
  // console.log('Is authenticated:', isAuthenticated)

  if (isLoginRequired(to.name) && !isLogin) {
    console.log('Redirecting to login')
    return {
      name: 'login',
      query: { redirect: to.fullPath }
    }
  }
  return true
})

// router.onError(err => console.error('vue-router err:', err))
router.onError(err => {
  console.error('vue-router err:', err)
  if (/Cannot access 'Base' before initialization/.test(err.message)) {
    console.error('Potential issue with class/module import order.')
    // 打印所有路由信息
    console.log('Current routes:', router.getRoutes())
  }
})


export default router
