/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-04-16 09:34:33
 * @FilePath: \js-yigrow-system\src\router\base-house.js
 * @Description: 基地管理
 */
const greenhouseManage = () => import('@/views/base-house/greenhouse-manage.vue')
const baseManage = () => import('@/views/base-house/position-manage.vue')

export default [
    {
        path: "/base-house/greenhouse-manage",
        name: "greenhouse-manage",
        component: greenhouseManage,
        meta: {
            layout: "ui",
            pertain: '区域管理',
            requiresAuth: true,
            title: "大棚管理",
        }
    },
    {
        path: "/base-house/position-manage",
        name: "position-manage",
        component: baseManage,
        meta: {
            layout: "ui",
            pertain:'区域管理',
            requiresAuth: true,
            title: "基地管理",
        }
    }
]