/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-04-12 11:50:30
 * @FilePath: \js-yigrow-system\src\configs\menus\greenhouse.js
 * @Description: 温室管理菜单列表
 */

export default [
  {
    icon: "mdi-dots-hexagon",
    text: "menuName.controlCenter",//环控中心
    link: "/greenhouse/environmentalControl",
    name:"greenhouse-control",
    menuKey: ["environmentalControl","drug"],
  },
  // 设备总控不显示了，换成从每个区域进入
  // {
  //   icon: "mdi-format-list-checkbox",
  //   text: "设备总控",
  //   link: "/greenhouse/deviceControl",
  //   name:"device-control",
  //   menuKey: ["environmentalControl"],
  // },
  // {
  //   icon: "mdi-email-check",
  //   text: "视频监控",
  //   link: "/greenhouse/video-surveillance",
  //   name:"greenhouse-video",
  //   menuKey: ['查询管理员信息'],
  // },
];

