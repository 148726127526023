import UserRoutes from "./user"
import AuthRoutes from "./auth"
import ScanRoutes from "./scan-router"
import AuthorityRoutes from "./authority"
import BaseHouseRoutes from "./base-house"
import GreenhouseRoutes from "./greenhouse"
import WaterFertilizer from "./water-ferti"


export const routes = [
  {
    path: "/",
    redirect: "/home-page",
    meta: {},
  },
  {
    path: "/home-page",
    name: 'home',
    meta: {
      requiresAuth: true,
      layout: "landing",
    },
    component: () => import("@/views/pages/home-page.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/errors/not-found-page.vue"),
  },

  {
    path: "/operateManage",
    meta: {
      requiresAuth: true,
      layout: "ui",
      pertain: '运营管理',
    },
    component: () => import("@/views/operation/operation-index.vue"),
  },


  ...ScanRoutes,
  ...UserRoutes,
  ...AuthRoutes,
  ...BaseHouseRoutes,
  ...AuthorityRoutes,
  ...GreenhouseRoutes,
  ...WaterFertilizer,
]


