
/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-07-13 16:02:07
 * @FilePath: \js-yigrow-system\src\configs\navigation.js
 * @Description: 菜单导航
 */

import menuGreenhouse from "./menus/greenhouse"
import fertilizerManage from "./menus/water-ferti-manage"
import energyControl from "./menus/energy-control"
import monitorScreen from "./menus/monitor-screen"
import authorityControl from "./menus/authority-control"
import baseManage from "./menus/base-manage"


export default {
  menu: [
    {
      text: "",
      items: [
        {
          text: "menuName.home",// 我的主页
          link: "/home-page",
          // icon: "mdi-view-dashboard-outline",
          icon: "mdi-home-variant-outline",
        },
      ],
    },

    {
      text: "",
      items: [
        {
          text: "menuName.productAdd",//产品添加
          link: "/scan-bind",
          icon: "mdi-printer-pos-plus-outline",
        },
      ],
    },
    {
      text: "运营管理",
      items: [
        {
          icon: "mdi-card-bulleted-settings-outline",
          text: "menuName.operationManage",//运营管理
          link: "/operateManage",
          name:"operateManage",
          // menuKey: ["管理员"],
        },
      ],
    },
    {
      text: "menuName.greenhouseManage",//温室管理
      items: menuGreenhouse,
    },
    {
      text: "menuName.productManage",//产品管理
      items: fertilizerManage,
    },
    // {
    //   text: "能源控制",
    //   items: energyControl,
    // },
    // {
    //   text: "监控&大屏",
    //   items: monitorScreen,
    // },
    {
      text: "menuName.permissionManage",//权限管理
      items: authorityControl,
    },

    {
      text: "menuName.baseManage",//基地管理
      items: baseManage,
    },
  ],
};
