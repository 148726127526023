<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-03-22 11:08:11
 * @FilePath: \js-yigrow-system\src\layouts\ui-layout.vue
 * @Description: ui主题
-->
<template>
  <!---Main Sidebar -->
  <main-sidebar />

  <!---Top AppBar -->
  <main-appbar />

  <!---MainArea -->
  <!-- 移动设备通过滑动控制菜单显示 -->
  <!-- v-touch="{
      left: () => (customizeTheme.mainSidebar = false),
      right: () => (customizeTheme.mainSidebar = true),
    }" -->

  <v-main class="main-container">
    <!-- <global-loading /> -->

    <div class="d-none d-sm-block px-3">
      <page-title />
      <breadcrumb></breadcrumb>
    </div>

    <div class="flex-fill">
      <slot></slot>
    </div>
    <!-- 右下角浮动按钮 -->
    <!-- <toolbox /> -->
  </v-main>
</template>

<script setup>

import { useCustomizeThemeStore } from "@/stores/customize-theme"

const customizeTheme = useCustomizeThemeStore();

</script>

<style scoped>
.scrollnav {
  height: calc(100vh - 326px);
}

.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
