/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-03-19 14:06:59
 * @FilePath: \js-yigrow-system\src\models\category-api.js
 * @Description: /category 分类接口
 */

import { Base } from "./base"
class CategoryService extends Base {
    constructor(opt) {
        super(opt)
    }

    /**查询所有分类 */
    getCategoryAll(params) {
        let url = '/all'
        return this.get(url, { params })
    }

    /**查询分类详情 */
    getCategoryInfo(categoryId) {
        let url = '/'
        return this.get(`${url}?categoryId=${categoryId}`,)
    }
}

export default new CategoryService({ prefix: '/v1/category' })