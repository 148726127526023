/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-03-06 08:59:47
 * @FilePath: \js-yigrow-system\src\router\authority.js
 * @Description: 权限管理
 */


const authorityControl = () => import('@/views/authority/authority-control.vue')


export default [
    {
        path: "/authority/sub-account",
        name: "sub-account",
        component: authorityControl,
        meta: {
            layout: "ui",
            pertain:'权限管理',
            requiresAuth: true,
            title: "员工管理",
        }
    }
]