/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-05-09 17:26:29
 * @FilePath: \js-yigrow-system\src\models\bis-api.js
 * @Description: 
 */
import { Base } from "./base"

class BisService extends Base {
    constructor(opt) {
        super(opt)
    }

    // 查询当前商户详情
    getBisDetail() {
        // return this.get(`/`)
        return this.get(`/`, { throwError: false })
    }

    // 创建新商户
    createBis(data) {
        return this.post(`/`, data)
    }

    // 修改商户信息
    updateBis(data) {
        return this.put(`/`, data)
    }

    // 删除商户信息
    deleteBis(id) {
        return this.delete(`/${id}`)
    }
}


export default new BisService({ prefix: '/v1/bis' });