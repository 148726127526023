/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-05-18 10:20:16
 * @FilePath: \js-yigrow-system\src\router\scan-router.js
 * @Description: 扫码绑定
 */

const addBind = () => import("@/views/scan/scan-bind.vue")
const appScan = () => import("@/views/scan/children/app-scan.vue")
const weixinScan = () => import("@/views/scan/children/weixin-scan.vue")

export default [
    {
        path: "/scan-bind",
        name: "scan-bind",
        component: addBind,
        meta: {
            requiresAuth: true,
            layout: "default",
        },
    },
    {
        path: "/appScan",
        name: "app-scan",
        component: appScan,
        meta: {
            requiresAuth: true,
            layout: "default",
        },
    },
    {
        path: "/weixinScan",
        name: "weixin-scan",
        component: weixinScan,
        meta: {
            requiresAuth: true,
            layout: "default",
        },
    },
]