/*
 * @Author: OnismYY 2848335733@qq.com
 * @Date: 2023-12-08 14:55:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-06-25 17:37:14
 * @FilePath: \js-yigrow-system\src\utils\common-utils.js
 * @Description: 公共处理函数
 */

/**判断传入值【数组、对象、参数】是否为空，,返回true为空，false不为空 */
export function isEmpty(val) {
  if (typeof val == 'boolean') {
    return false
  }
  if (typeof val == 'number') {
    return false
  }
  if (val instanceof Array) {
    if (val.length == 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (
      val == 'null' ||
      val == null ||
      val == 'undefined' ||
      val == undefined ||
      val == ''
    )
      return true
    return false
  }
  return false
}

// 检查两个字符串集合是否相等，（值相同，顺序不同可判断）
export function isSetEqual(set1, set2) {
  if (set1.size !== set2.size) {
    return false
  }
  for (const item of set1) {
    if (!set2.has(item)) {
      return false
    }
  }
  return true
}

/**
 * @description:
 * @param {*} id  要查询的id值，可以是id||bisId||productId
 * @param {*} dataList  在哪个数组中查找id相同的对象
 * @return {*}  返回detaList中id与传入id相同的对象下的name，实现 id匹配name
 */
export const getNameById = (id, dataList) => {
  if (isEmpty(id) || dataList.length === 0) {
    return ''
  }
  let perObj = dataList.find(
    (item) => item.id === id || item.bisId === id || item.productId === id
  )
  return perObj ? perObj.name : '未知'
}

/**
 * 根据不同状态，返回不同文字及颜色
 */
export function judgeStatus(val, type) {
  const mappings = {
    color: {
      0: 'error',
      1: 'success',
      2: 'warning',
      3: 'grey'
    },
    text: {
      0: '已停止',
      1: '工作中',
      2: '故障',
      3: '休眠'
    }
  }
  if (!isEmpty(val) && mappings[type]) {
    return mappings[type][val] || '未知'
  }
}

// 用于格式化文件大小，将文件大小转换为可读性更好的字符串。
// 参数 size 是文件大小，返回格式化后的字符串。
// Format file size
export const formatFileSize = (size) => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let unitIndex = 0

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024
    unitIndex++
  }

  return `${size.toFixed(2)} ${units[unitIndex]}`
}

/**
 * 随机字符串, 只有字母,数字,下划线
 * @param {number} len 长度
 */
export function generateNonce(len = 24) {
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_'
  let noceStr = '',
    maxPos = chars.length
  while (len--) noceStr += chars[(Math.random() * maxPos) | 0]
  return noceStr
}

/**
 * promise 简洁写法
   const {promise, resolve, reject} = new Deferred()

   return promise
 */
export class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
}

// 当index为js的偶数时，返回480，否则返回240
const getPhotoHeight = (index) => {
  let height = 0
  if (index % 3 === 0) {
    height = 480
  } else if (index % 3 === 1) {
    height = 240
  } else {
    height = 360
  }
  return height
}

// 将对象中的某些字段由String转为Number
// 使用方式 convertToNumber(editedItem.value, ['peopleNum', 'price', 'weight'])
//将editedItem.value中的'peopleNum', 'price', 'weight' 转为number
const convertToNumber = (obj, properties) => {
  properties.forEach((property) => {
    if (!isNaN(obj[property])) {
      obj[property] = +obj[property]
    } else {
      console.log('no isNaN')
    }
  })
}

/**
 * @description: 根据deviceType返回对应中文名
 * @return {*}
 */
const deviceNames = {
  pump: '水泵',
  sensor: '传感器',
  valve: '进口阀',
  btValve: '蝶阀',
  tank: '水池',
  flowmeter: '流量计',
  irrValve: '电磁阀',
  branchValve: '支管电磁阀',

  //
  flowmeter: '流量计',
  fan: '风机',
  light: '补光灯',

  // 水肥中心
  ecphSensor: 'EC&pH监测',
  dosingFerValve: '吸肥电磁阀',
  dosingPHValve: '吸酸电磁阀',
  dosingPump: '吸肥泵',
  mixPump: '搅拌泵'
}
export const getDeviceName = (deviceType) => {
  if (isEmpty(deviceType)) return '未知'
  return deviceNames[deviceType] || deviceType
}

/**
 * @description: 根据startupType(启动类型)返回对应中文名
 * @param {*} val
 * @return {*}
 */
export const getStartupTypeTitle = (val) =>
  ({
    FRQ: '变频器启动',
    DO: 'DO直启'
  }[val] || '')



/**
 * @description: 根据控制类型，返回控制名称
 * @return {*}
 */
const controlNames = {
  drug: '打药',
  environmentalControl: '环控中心',
  fertilizer: '水肥系统',
  atomizing: '自动'
}
export const getContainerName = (containerType) => {
  if (isEmpty(containerType)) return '未知'
  return controlNames[containerType] || containerType
}

/**
 * @description: 根据控制类型，返回控制名称
 * @return {*}
 */
const weekNames = {
  1: '星期一',
  2: '星期二',
  3: '星期三',
  4: '星期四',
  5: '星期五',
  6: '星期六',
  7: '星期天'
}
export const getWeeksrName = (containerType) => {
  if (isEmpty(containerType)) return '未知'
  return weekNames[containerType] || containerType
}
