/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-04-17 13:35:43
 * @FilePath: \js-yigrow-system\src\configs\locales.js
 * @Description: 
 */

import enMessages from "@/locales/en"
import zhHansMessages from "@/locales/zhHans"
import cache from "@/utils/cache"



// 先使用缓存中的语言环境
const sessionStorage = cache.get('customizeTheme')
const localCode = sessionStorage?.localCode || null

const supported = ["en", "zhHans"]
let locale = "zhHans"

try {
  const { 0: browserLang } = navigator.language.split("-")
  if (supported.includes(browserLang)) locale = browserLang
} catch (e) {
  console.log(e)
}

export default {
  // current locale
  locale: localCode || locale,

  // when translation is not available fallback to that locale
  fallbackLocale: "zhHans",

  // availabled locales for user selection
  availableLocales: [
    {
      code: "zhHans",
      flag: "cn",
      name: "china",
      label: "中文",
      messages: zhHansMessages,
    },
    {
      code: "en",
      flag: "us",
      name: "united-states",
      label: "English",
      messages: enMessages,
    },
  ],
  messages: {
    en: enMessages,
    zhHans: zhHansMessages,
  },
}
