import toastMessage from "@/components/toast-message"

/**
 * 
 * @param {string} message 
 * @param {string} color 'success', 'error','warning'
 * @param {string} variant 'flat', 
 */
export function showNotify(message, color = 'success', variant) {
  toastMessage.show({ color, message, })
}