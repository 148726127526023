/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-06-05 10:08:31
 * @FilePath: \js-yigrow-system\src\views\pages\fast-app\fast-store.js
 * @Description: 
 */

import { defineStore } from 'pinia'

const shortcuts = {
  'menuName.controlCenter'://环控中心
    { icon: "#icon-wodeliucheng", link: "/greenhouse/environmentalControl", menuKey: ["environmentalControl", "drug"], },
  'controlCenter.deviceControl'://设备总控
    { icon: "#icon-duozhanduibi", link: "", menuKey: [], },
  'menuName.waterFertilizerCenter'://水肥中心
    { icon: "#icon-shuihaotongji", link: "", menuKey: [], },
  'menuName.spraySystem'://喷雾系统
    { icon: "#icon-liuliangqushi", link: "", menuKey: [], },
  '视频监控'://视频监控
    { icon: "#icon-lishishuju", link: "", menuKey: [], },
  '组态大屏'://组态大屏
    { icon: "#icon-dunshuihaodianliang", link: "", menuKey: [], },
  '大屏监控'://大屏监控
    { icon: "#icon-shishishuju", link: "", menuKey: [], },
  'menuName.drugSystem'://打药系统
    { icon: "#icon-dingdiantouyao", link: "/water-ferti/dosing-system", menuKey: ["drug"], },
  'menuName.balconyGarden'://阳台种菜机
    { icon: "#icon-qingcai", link: "/water-ferti/vegetable-plant", menuKey: ["planting"], },
  'menuName.inOutWaterFertilizer'://进取型水肥控制系统
    { icon: "#icon-qingcai", link: "/water-ferti/vegetable-plant", menuKey: ["inoutfertilizer"], },
  'menuName.shedManage'://大棚管理
    { icon: "#icon-wenshidapengguanli", link: "/base-house/base-manage", menuKey: ["environmentalControl"], },
}


/**
* 根据名称数组筛选快捷方式对象
* @param {Array} names 名称数组 例如 [ "打药系统", "阳台种菜机", ]
* @returns {Array} 筛选后的快捷方式对象数组
*/
export function filterShortcuts(names) {
  const filteredShortcuts = []

  names.forEach(menuName => {
    const shortcut = shortcuts[menuName]
    if (shortcut) {
      filteredShortcuts.push({
        name: menuName,
        icon: shortcut.icon,
        link: shortcut.link,
        menuKey: shortcut.menuKey
      })
    }
  })

  return filteredShortcuts
}


export const useFastStore = defineStore({
  id: 'fastStore',
  state: () => ({
    visibleMenuNames: [],//快捷应用所有显示的菜单
  }),
  persist: {
    paths: ['visibleMenuNames'],
    storage: sessionStorage,
  },
  getters: {
    getPreviousRoute() {
      return this.previousRoute
    },
  },
  actions: {
    // 更新存储的菜单项名称数组
    updateVisibleMenuNames(menuNames) {
      this.visibleMenuNames = menuNames
    },
  }
})


