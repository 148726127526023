/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-04-11 17:22:09
 * @FilePath: \js-yigrow-system\src\plugins\index.js
 * @Description: 
 */

// Plugins
import vuetify from './vuetify'
import router from '@/router'
import pinia from '@/stores'


import i18n from "./i18n";

//优化滚动条，防抖，节流
import PerfectScrollbar from "vue3-perfect-scrollbar";
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

// 瀑布流布局
import MasonryWall from "@yeger/vue-masonry-wall"

// 本地公共组件
import confirmDialog from '@/components/common/confirm-dialog.vue';
import timePicker from '@/components/common/time-picker.vue';

// styles
import "@/styles/main.scss";
import "@/assets/iconfont/iconfont.js" //阿里巴巴彩色图标
import "@/assets/iconfont/iconfont.css" 
import "@/styles/siba.scss"


export function registerPlugins(app) {
  try {
    // 使用链式调用并引入异常处理
    app.use(vuetify)
      .use(router)
      .use(pinia)
      .use(i18n)
      .use(PerfectScrollbar)
      .use(MasonryWall);

    // 注册组件，同样添加异常处理
    registerComponents(app);
  } catch (error) {
    console.error("插件或组件注册失败:", error);
  }
}

// 将组件注册逻辑分离到一个独立的函数中以提高可维护性
function registerComponents(app) {
  try {
    app.component('confirm-dialog', confirmDialog) // 确认dialog
      .component('timePicker', timePicker);  // 时间选择器
  } catch (error) {
    console.error("组件注册失败:", error);
  }
}
