<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-01-29 09:08:40
 * @FilePath: \vite-yigrow-system\src\layouts\default-layout.vue
 * @Description: default主题
-->
<template>
  <div>
    <slot> </slot>
  </div>
</template>
