//存储 localStorage

class Cache {

  save(key, value, ttl = 0, expires) {
    if (ttl) expires = +new Date() + ttl * 1000
    localStorage.setItem(this.genKey(key), JSON.stringify({ value, ttl, expires }))
  }

  set(key, value, ttl = 0, expires) {
    this.save(key, value, ttl, expires)
  }

  get(key) {
    let res = this.getTTL(key)
    if (res?.ttl && res?.expires <= +new Date()) {
      localStorage.removeItem(this.genKey(key))
      return null
    }
    return res?.value
  }

  getTTL(key) {
    let res = localStorage.getItem(this.genKey(key))
    if (!res) return res
    try {
      res = JSON.parse(res)
      return res
    } catch (e) {
      console.log(e)
      localStorage.removeItem(this.genKey(key))
    }
  }

  remove(key) {
    localStorage.removeItem(this.genKey(key))
  }

  removeAll() {
    localStorage.clear()
  }

  genKey(key) {
    return `yigrow:cache:${key}`
  }
}

export default new Cache()

