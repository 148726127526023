<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-02-29 14:57:00
 * @FilePath: \js-yigrow-system\src\components\toast-message\toast-message.vue
 * @Description: 消息条
-->
<template>
  <v-snackbar :timeout="3000" :color="color" :variant="variant" v-model="snackbar" location="top">{{ message }}
    <template v-slot:actions>
      <v-btn icon="$close" @click="snackbar = false"></v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
defineProps(["message", "color", "variant"])

// 信息提示状态 Props接受的数据只读
const snackbar = ref()

// 调用即显示
const show = () => {
  snackbar.value = true
}

defineExpose({
  show,
})
</script>
