/**
 * 当前工作基地
 */
import { defineStore } from 'pinia'
import bisApi from '@/models/bis-api'
import user from '@/models/user-api.js'
import token from '@/models/token.js'
import { showNotify } from '@/utils/show'
import router from '@/router'
// import biz from '@/models/biz.js'
import productApi from "@/models/product-yi-api"
// import userApi from '@/models/user-api'
// import tokenApi from "@/models/token-api"
import categoryApi from '@/models/category-api'
import groupYiApi from '@/models/group-yi-api.js'

export const useBisStore = defineStore(
  'bis',
  () => {
    const bis = ref() // 当前子帐户 所属 基地 info
    const bisId = ref('') // 当前子帐户 所属 基地ID
    const name = ref('') // 
    let oldBis
    const productList = ref([])
    const productTypeList = ref([])
    const deviceTypeList = ref([])
    const rolePermission = ref([])
    const areaList = ref([])

    async function getBisInfo() {
      let res = await bisApi.getBisDetail()
      oldBis = bis.value = res
      bisId.value = res.id
      name.value = res.name
    }

    // 切换当前所属商户
    async function changeBis(bisId) {
      // const { id, name } = bisInfo
      if (oldBis?.id === bisId) return
      try {
        console.log('切换商户>>>', bisId,)

        const res = await token.changeMerchant({ bisId },)

        console.log('切换商户:', res)
        showNotify(res.message)

        await initBis()

        if (router.currentRoute.value.path === '/home-page') {
          location.reload()
        } else {
          router.push({ path: "/home-page" })
        }

        // await this.updateCurrentBis(bisInfo)

      } catch (err) {
        showNotify(err.message, 'error')
        console.log('切换失败了>>>>>', err)
        bis.value = { ...oldBis }
        // Object.assign(this.currentBis, this.oldBis)
      }
    }

    // 获取所有产品
    async function getProductList() {
      // 获取产品类型列表
      const res = await productApi.getProductAll()
      // 提取类型，使用set确保产品类型的唯一
      const productTypesSet = new Set(res.map((item) => item.productType))
      // 将Set转为数组
      productTypeList.value = Array.from(productTypesSet)
      // console.log('当前商户产品类型列表>>>', productTypeList)
      // 根据id去除重复的产品列表
      const uniqueProducts = new Map()
      res.forEach(item => {
        uniqueProducts.set(item.id, { productId: item.id, name: item.name })
      })
      productList.value = Array.from(uniqueProducts.values())
      // console.log('当前商户所有产品列表>>>', this.productList)
    }

    // 获取所有设备类型
    async function getCategories() {
      const res = await categoryApi.getCategoryAll({ isRoot: 0 })
      // console.log('所有设备类型>>>', res)
      deviceTypeList.value = res
        .filter(item => item.abbr) // 添加过滤条件，只保留有abbr字段的项
        .map(item => ({ id: item.id, name: item.name, abbr: item.abbr }))
    }

    async function deleteBis(bisId) {
      try {
        console.log('rm 商户>>>', bisId,)
        const res = await bisApi.remove(bisId)
        showNotify(res.message) // variant: 'flat'
      } catch (err) {
        showNotify(err.message, 'error')
        console.error(err)
      }
    }

    async function initBis() {
      await getBisInfo()
      await getCategories()
      await getProductList()
      await getAllPermission()
    }

    // 获取所有权限
    async function getAllPermission() {
      try {
        const res = await user.getPermissions()
        // 提取groups数组对象中的name组成角色权限数组
        rolePermission.value = res.groups.flatMap(item => item.name)
        // showNotify(res.message)
      } catch (err) {
        showNotify(err.message, 'error')
        console.error('getAllPermission', err)
      }
    }

    // {name, phone, address, img, loc}
    async function createBis(data) {
      try {
        const res = await bisApi.createBis(data)
        showNotify(res.message) // variant: 'flat'
      } catch (err) {
        showNotify(err.message, 'error')
        console.error(err)
      }
    }
    // {id, name, phone, address, img, loc}
    async function updateBis(data) {
      try {
        const res = await bisApi.updateBis(data)
        showNotify(res.message) // variant: 'flat'
      } catch (err) {
        showNotify(err.message, 'error')
        console.error(err)
      }
    }

    async function getAreaList(query) {
      const res = await groupYiApi.getGroupYiList(query)
      // const filteredList = res.filter((item) => item.isRoot === 1)
      // console.log('所有位置列表', res)
      areaList.value = res.map((item) => ({
        name: item.name,
        id: item.id
      }))
    }
    return {
      bis, bisId, name, productList, productTypeList, deviceTypeList,
      rolePermission, areaList,
      getBisInfo, changeBis, initBis, deleteBis, createBis, updateBis,
      getAreaList,
    }
  },
  {
    persist: true,  // default localStorage
    // persist: { key: 'store:app', } // default $id
  }
)