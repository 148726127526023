/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-06-20 09:13:14
 * @FilePath: \js-yigrow-system\src\main.js
 * @Description: 
 */
import { createApp } from 'vue'
import App from './App.vue'
import 'core-js/features/array/at'
import { registerPlugins } from '@/plugins'
import handleError from './utils/monitor.js'
// 移动设备+开发环境，初始化eruda 调试工具
import eruda from 'eruda'
import { wait } from './utils/utils'

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
if (import.meta.env.VITE_ENV === 'development' && isMobile) {
  eruda.init()
}

const app = createApp(App)

registerPlugins(app)
handleError(app)
// console.log('app-config:', app.config)
app.mount('#app')
