<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn icon v-bind="props">
        <v-icon color="primary">mdi-translate</v-icon>
      </v-btn>
    </template>
    <v-list nav>
      <v-list-item v-for="locale in availableLocales" :key="locale.code" @click="setLocale(locale.code)"
        density="compact" :active="locale.code === current">
        <template v-slot:prepend>
          <Icon :icon="`twemoji:flag-${locale.name}`" class="mr-2" />
        </template>
        <v-list-item-title> {{ locale.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import { Icon } from "@iconify/vue"
import { useLocale } from "vuetify"
import { useCustomizeThemeStore } from "@/stores/customize-theme"
import locales from "@/configs/locales"
const { current } = useLocale()
const customizeTheme = useCustomizeThemeStore()
const { availableLocales } = locales

onMounted(() => {
  setLocale(customizeTheme.localCode)
})

const setLocale = (locale) => {
  current.value = locale
  customizeTheme.setLocalCode(locale)
}
</script>
