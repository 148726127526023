<!--
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-06-21 08:06:43
 * @FilePath: \js-yigrow-system\src\components\common\confirm-dialog.vue
 * @Description: 确认dialog
-->
<template>
  <v-dialog v-model="config.isShow" max-width="360">
    <v-card class="pa-2">
      <v-card-title class="text-headline">{{ config.title }}</v-card-title>
      <v-card-text class="text-medium-emphasis" v-if="!isEmpty(config.description)">
        {{ config.description }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="plain" @click="closeDialog">
          {{ config.cancelText || "取消" }}
        </v-btn>
        <v-btn variant="flat" color="primary" @click="confirmDelete">{{
    config.confirmText || "确定"
          }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { isEmpty } from '@/utils/common-utils.js'

defineProps({
  config: {
    type: Object,
    default: () => ({
      isShow: false,
      title: "",
      type:"",
      description: "",
      cancelText: "取消",
      confirmText: "确定",
    }),
  },
})

const emits = defineEmits(["close", "confirm"])

const closeDialog = () => {
  emits("close")
}

const confirmDelete = () => {
  emits("confirm")
}
</script>

<style scoped></style>
