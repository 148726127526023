/*
 * @Author: OnismYY 2848335733@qq.com
 * @LastEditTime: 2024-03-22 13:36:52
 * @FilePath: \js-yigrow-system\src\router\auth.js
 * @Description: 密码登录、邮箱登录、退出登录、忘记密码
 */
const signinPageVue = () => import(/* webpackChunkName: "auth-signin" */ "@/views/auth/signin-page.vue")
const signupPageVue = () => import(/* webpackChunkName: "auth-signup" */ "@/views/auth/signup-page.vue")
const forgotPageVue = () => import(/* webpackChunkName: "auth-forgot" */ "@/views/auth/forgot-page.vue")
const verifyEmailPageVue = () => import(/* webpackChunkName: "verify-email" */ "@/views/auth/verify-email-page.vue")

export default [
  {
    path: "/auth/signin",
    name: "login",
    component: signinPageVue,
    meta: {
      layout: "auth",
      title: "SignIn",
    },
  },
  {
    path: "/auth/signup",
    name: "signup",
    component: signupPageVue,
    meta: {
      layout: "auth",
      title: "SignUp",
    },
  },
  {
    path: "/auth/forgot-password",
    name: "auth-forgot",
    component: forgotPageVue,
    meta: {
      layout: "auth",
      title: "SignForgot",
    },
  },
  {
    path: "/auth/verify-email",
    name: "verify-email",
    component: verifyEmailPageVue,
    meta: {
      requiresAuth: true,
      layout: "auth",
      title: "VerifyEmail",
    },
  },
]
